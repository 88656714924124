import { vuexfireMutations } from 'vuexfire'
import { firestore, Timestamp } from '@/plugins/firestore'

const COLLECTION_NAME_OPERATOR =
  process.env.ctiEnv === 'production'
    ? 'operators_funagata'
    : 'operators_funagata_dev'

export const mutations = {
  ...vuexfireMutations,

  updatePhoneNumber(state, { phoneNumber }) {
    state.phoneNumber = phoneNumber
  },
  updateVerCode(state, { verCode }) {
    state.verCode = verCode
  },
  updateUserId(state, { userID }) {
    state.userID = userID
  },
  setVehicleId(state, { vehicleId }) {
    state.showRoutesOfVehicle = vehicleId
  },
  updateTimeBuffer(state, { timeBuffer }) {
    state.timeBuffer = timeBuffer
  }
}

export const state = () => ({
  phoneNumber: '',
  verCode: '',
  userID: '',
  showRoutesOfVehicle: -1,
  timeBuffer: ''
})

export const actions = {
  setOperatorAvailability: async ({ state }, { available }) => {
    if (state.auth && state.auth.user && state.auth.user.id) {
      const data = { id: state.auth.user.id, available }
      const ref = await firestore
        .collection(COLLECTION_NAME_OPERATOR)
        .doc(`operator${state.auth.user.id}`)
      const item = await ref.get()
      if (item.exists) {
        await ref.update({ available, updated_at: Timestamp.now() })
        return item.data().id
      }
      return ref.set(data).then(() => {
        return data.id
      })
    }
    return Promise.reject(new Error('Not authorized'))
  },

  getOperator: async ({ state }) => {
    if (state.auth && state.auth.user && state.auth.user.id) {
      const item = await firestore
        .collection(COLLECTION_NAME_OPERATOR)
        .doc(`operator${state.auth.user.id}`)
        .get()
      if (item.exists) {
        return item.data()
      }
    }
    return Promise.reject(new Error('Not authorized'))
  },

  // 同期用の為に doc を取得する
  getOperatorDoc: async ({ state }) => {
    if (state.auth && state.auth.user && state.auth.user.id) {
      const ref = await firestore
        .collection(COLLECTION_NAME_OPERATOR)
        .doc(`operator${state.auth.user.id}`)
      return ref
    }
    return Promise.reject(new Error('Not authorized'))
  }
}
